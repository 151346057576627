import * as React from 'react';
import {
  Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination,
  TableRow, Paper, Checkbox, IconButton
} from '@mui/material';
import { ReactComponent as LinkArrow } from "../../assets/icons/link-arrow.svg";
import { ReactComponent as ChevronUpDown } from "../../assets/icons/chevron-up-down.svg";


// Sample Data
const rowsDataSample = [
  { id: 1, qty: 1, product: 'Leather Comfort Chaise', vendor: 'Visual Comfort', room: 'Living Room', category: 'Furniture', link: '#', availability: 'In Stock', ordered: false },
];

// Columns Definition
const columns = [
  { id: 'qty', label: 'Qty', numeric: true },
  { id: 'product', label: 'Product', numeric: false },
  { id: 'vendor', label: 'Vendor', numeric: false },
  { id: 'room', label: 'Room', numeric: false },
  { id: 'category', label: 'Category', numeric: false },
  { id: 'link', label: 'Link', numeric: false },
  { id: 'availability', label: 'Availability', numeric: false },
  { id: 'ordered', label: 'Ordered', numeric: false },
];

// Sorting function
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
    : (a, b) => (b[orderBy] > a[orderBy] ? -1 : 1);
}

// Table Head with Always Visible Chevron Icon
function EnhancedTableHead({ order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ backgroundColor: '#F9FAFB', height: '45px' }}>
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.id}
            align={column.numeric ? 'right' : 'left'}
            sx={{ fontSize: '0.875rem', fontWeight: 600, cursor: 'pointer' }}
            onClick={createSortHandler(column.id)}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <span>{column.label}</span>
              <div className='px-1'>

                <ChevronUpDown
                  style={{
                    transform: orderBy === column.id && order === 'desc' ? 'rotate(180deg)' : 'none',
                    transition: 'transform 0.2s ease-in-out',
                  }}
                />
              </div>
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const formattedStock = (stock)=>{
  if(stock === 'out of stock' || stock=== 'Out of Stock' ){
    return 'Out of Stock'
  }else if(stock === 'in stock' || stock=== 'In Stock'){
    return 'In Stock'
  }else if(stock === 'Low Stock' || stock === 'low stock'){
    return 'Low Stock'
  }
}

// Main Table Component
export default function SpreadSheetTable({ rowsData = [], selectHandler }) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('product');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    setOrder(orderBy === property && order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Sort rows before rendering
  const sortedRows = [...rowsData].sort(getComparator(order, orderBy));

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2, borderRadius: '10px', overflow: 'hidden' }}>
        <TableContainer>
          <Table>
            <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            <TableBody>
              {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <TableRow key={row.id} hover>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.numeric ? 'right' : 'left'}>
                      {(column.id === 'link' && row[column.id]) ? (
                        <IconButton component="a" href={row[column.id]} target="_blank">
                          <LinkArrow />
                        </IconButton>
                      ) : column.id === 'ordered' ? (
                        <Checkbox
                        checked={row[column.id]}
                        onChange={(e) => selectHandler(row, e.target.checked)}
                      />
                      ) : column.id === 'availability' ? (
                        <span style={{ color: row[column.id] === 'In Stock' || row[column.id] === 'in stock' ? 'green' : row[column.id] === 'Low Stock' || row[column.id] === 'low stock' ? 'orange' : row[column.id] === 'Out of Stock' || row[column.id] === 'out of stock' ? 'red' : 'gray' }}>
                          {formattedStock(row[column.id])}
                        </span>
                      ) : (
                        <span className='capitalize'>
                          {row[column.id]}
                        </span>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Right-aligned pagination */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
          <TablePagination
            count={sortedRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, newPage) => setPage(newPage)}
          />
        </Box>
      </Paper>
    </Box>
  );
}
