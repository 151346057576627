import { createTheme, alpha, styled, Button } from "@mui/material";

export const colors = {
  primary: "#000000",
  secondary: "#0A77FF",
  background: {
    white: "#FFFFFF",
    gray: "#F8F8F8",
  },
  success: "#0F973D",
  danger: "#D42620",
  white: "#FFFFFF",
};

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    text: {
      primary: colors.primary,
      white: colors.background.white,
      black: colors.primary,
    },
    background: {
      default: colors.background.gray,
      paper: colors.background.white,
    },
    error: {
      main: colors.danger,
    },
    success: {
      main: colors.success,
    },
  },
  typography: {
    fontFamily: "Inter",
  },
  components: {
    MuiFormControlLabel: {
      defaultProps: {
        sx: {
          marginX: 0,
          gap: "3px",
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        sx: {
          padding: 0,
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        sx: {
          boxShadow: "none",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: "contained",
        sx: {
          color: colors.white,
          width: "100%",
          textTransform: "none",
          padding: "0.875rem", // p-4
          borderRadius: "0.5rem", // lg
          fontWeight: "semibold",
          fontSize: "1rem", // base
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        sx: {
          "& .MuiInputBase-root": {
            borderRadius: "0.5rem",
          },
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        sx: {
          "& .MuiMenu-list": {
            padding: "10px !important",
          },
          "& .MuiMenuItem-root": {
            borderRadius: "5px",
          },
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        sx: {
          "& .MuiSwitch-switchBase": {
            "&.Mui-checked": {
              color: colors.primary,
              "& .MuiSwitch-thumb": {
                color: 'black',
              },
              "& + .MuiSwitch-track": {
                opacity: 0.2,
              },
            },
          },
          "& .MuiSwitch-track": {
            backgroundColor: colors.error,
            opacity: 0.3,
          },
          "& .MuiSwitch-thumb": {
            color: colors.error,
          },
        },
      },
    },
    MuiTab: {
      defaultProps: {
        sx: {
          textTransform: "none",
          fontSize: "1rem",
          borderBottom: "2px solid",
          "&.MuiTab-root.Mui-selected": {
            backgroundColor: `${colors.primary} !important`,
            transition: "all .4s",
            color: colors.white,
          },
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
        square: true,
        sx: {
          boxShadow: "none",
        },
      },
    },
    MuiAccordionDetails: {
      defaultProps: {
        sx: {
          border: `thin solid ${alpha(colors.primary, 0.1)}`,
          borderTop: `0px`,
        },
      },
    },
    MuiRating: {
      defaultProps: {
        max: 5,
        readOnly: true,
        precision: 0.1,
        sx: {
          color: colors.white,
        },
      },
    },
    MuiSkeleton: {
      defaultProps: {
        animation: "wave",
        className: "w-full !h-full",
        sx: {
          transformOrigin: 0,
          transform: "scale(1)",
          backgroundColor: alpha("#000000", 0.05),
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        sx: {
          "& .MuiDialog-paper": {
            width: "100% !important",
            marginX: "10px !important",
          },
        },
      },
    },
  },
});

export default theme;
