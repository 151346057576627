import { createSlice } from "@reduxjs/toolkit";

const generalSlice = createSlice({
  name: "general",
  initialState: {
    showSpreadSheet: false,
  },
  reducers: {
    setShowSpreadSheet: (state, action) => {
      state.showSpreadSheet = action.payload;
    },
  },
});

export const { setShowSpreadSheet } = generalSlice.actions;
export default generalSlice.reducer;
