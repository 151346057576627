import React from 'react';
import { Box, Typography, IconButton, List, ListItemIcon, ListItemText, Switch, ListItem } from '@mui/material';
import { ReactComponent as AddIcon } from "../../../assets/icons/plus.svg";
import { ReactComponent as RemoveIcon } from "../../../assets/icons/minus.svg";
import Checkbox from '../../../components/Checkbox';

const CheckboxItem = ({ label, isChecked = false, onChange }) => {
    const [checked, setChecked] = React.useState(isChecked);

    const handleCheck = () => {
        setChecked((prev) => !prev);
        onChange(label, !checked);
    };

    return (
        <ListItem disablePadding>
            <ListItemIcon>
                <Checkbox
                    onChange={handleCheck}
                    sx={{
                        width: 32,
                        height: 32,
                        color: '#D0D5DD',
                        '&.Mui-checked': { color: '#28282A' },
                    }}
                    checked={checked}
                    disableRipple
                />
            </ListItemIcon>
            <ListItemText primary={label} primaryTypographyProps={{ variant: 'body2', color: 'textSecondary' }} />
        </ListItem>
    );
};

const CategorySection = ({ title, items, isExpanded = false, onSelect }) => {
    const [expanded, setExpanded] = React.useState(isExpanded);

    const toggleExpand = () => setExpanded((prev) => !prev);

    return (
        <Box sx={{ bgcolor: 'white', p: 2, borderRadius: 1 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
                <Typography variant="body1">{title}</Typography>
                {items.length > 0 ? (
                    <IconButton onClick={toggleExpand}>
                        {expanded ? <RemoveIcon className='text-[20px]' /> : <AddIcon className='text-[20px]' />}
                    </IconButton>
                ) : (
                    null
                )}
            </Box>
            {expanded && items.length > 0 && (
                <List disablePadding>
                    {items.map((item, index) => (
                        <CheckboxItem key={`${title}-${index}`} label={item} onChange={onSelect} />
                    ))}
                </List>
            )}
            {items.length === 0 && (
                <CheckboxItem label={title} onChange={onSelect} />
            )}
        </Box>
    );
};

const FilterSidebar = ({ setSearchState }) => {
    const [expanded, setExpanded] = React.useState(false);
    
    const brands = [{ label: 'Brand', items: ['Wayfair', 'Fourhands', 'Dovetail'] }]
    const categories = [
        { label: 'Category', items: ['Furniture', 'Lighting & Fans', 'Kitchen & Dining', 'Rugs', 'Outdoor & Garden','Kids & Nursery',"Organization",'Fixtures','Building Materials','Window Treatments','Seasonal & Specialty','Other'] },
    ];

    const handleSelect = (label, isChecked) => {
        setSearchState((prevState) => {
            const updatedSearch = isChecked
                ? prevState.search ? `${prevState.search},${label}` : label
                : prevState.search.split(',').filter(item => item !== label).join(',');
            return { ...prevState, search: updatedSearch };
        });
    };
    
    const handleBrandSelect = (label, isChecked) => {
        setSearchState((prevState) => {
            const fromBrand = isChecked
                ? prevState.from ? `${prevState.from},${label}` : label
                : prevState.from.split(',').filter(item => item !== label).join(',');
            return { ...prevState, from: fromBrand };
        });
    };



    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: 220, minWidth: 220 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    Direct Brands Only
                </Typography>
                <Switch checked={expanded} onChange={() => setExpanded((prev) => !prev)} color='black' />
            </Box>
            <Box sx={{ bgcolor: 'white', borderRadius: 1 }}>
                {/* <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ p: 2 }}>
                    <Typography variant="body1">Brand</Typography>
                    <IconButton>
                        <AddIcon className='text-[20px]' />
                    </IconButton>
                </Box> */}
            {brands.map((category, index) => (
            <CategorySection key={index} title={category.label} items={category.items} onSelect={handleBrandSelect} />
            ))}
            </Box>

            {categories?.length > 0 && (
              categories.map((category, index) => (
                <CategorySection key={index} title={category.label} items={category.items} onSelect={handleSelect} />
            ))
            )}
        </Box>
    );
};

export default FilterSidebar;
