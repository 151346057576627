import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import nodeApi from "../services/nodeApi";
import authSlice from "./slice/authSlice";
import generalSlice from "./slice/generalSlice";
import propertySlice from "./slice/propertySlice";
import { propertyApi } from "./api/propertyApi";
import { propertySheetApi } from "./api/propertySheetApi";

const rootReducer = combineReducers({
  user: authSlice,
  property: propertySlice,
  general:generalSlice,
  [propertyApi.reducerPath]: propertyApi.reducer,
  [nodeApi.reducerPath]: nodeApi.reducer,
  [propertySheetApi.reducerPath]: propertySheetApi.reducer
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "property"],
  blacklist: [nodeApi.reducerPath, propertyApi.reducerPath, propertySheetApi.reducerPath]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(nodeApi.middleware).concat(propertyApi.middleware).concat(propertySheetApi.middleware),
});

export const persistor = persistStore(store);

export default store;
