import { useState, useEffect, useCallback, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

// Components
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import PageWrapper from "../components/PageWrapper";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import CatalogDetailModal from "../../../components/CatalogDetailModal";
import PropertySkeleton from "../../../components/PropertySkeleton";
import Pagination from "../../../components/Pagination/Pagination";
import ImageComponent from "../../../components/Image/ImageComponent";
import ShareDesign from "../../../components/Share/ShareDesign";
import RoomImages from "../../../components/PropertySheet/RoomImages";

// Icons
import { IconButton, Tooltip } from "@mui/material";
import { ReactComponent as Search } from "../../../assets/icons/search.svg";
import { ReactComponent as Add } from "../../../assets/icons/add.svg";
import { ReactComponent as Delete } from "../../../assets/icons/delete.svg";
import { ReactComponent as Eye } from "../../../assets/icons/password-visible.svg";
import { ReactComponent as Show } from "../../../assets/icons/open.svg";
import { ReactComponent as Hide } from "../../../assets/icons/hide.svg";
import { ReactComponent as AddCircle } from "../../../assets/icons/add-circle.svg";
import { ReactComponent as Back } from "../../../assets/icons/back.svg";
import { ReactComponent as Open } from "../../../assets/icons/open.svg";




// Utils & Services
import { uploadFile } from "../../../services/amplify/property";
import { getUser } from "../../../services/amplify/helpers";
import getCurrency from "../../../utils/getCurrency";

// Redux
import {
  addImage,
  addProduct,
  removeImage,
  removeProduct,
  setPropertySheet,
  setSelectedRoom,
  updateProduct
} from "../../../store/slice/propertySlice";
import {
  useFetchCatalogProductsQuery,
} from "../../../store/api/propertyApi";

import { useCreatePropertySheetMutation, useFetchPropertySheetVersionsQuery, useUpdatePropertySheetMutation } from "../../../store/api/propertySheetApi";
import FilterSidebar from "../components/FiltersSection";
import { formatBrandName, getFormattedImageLink } from "../../../utils/format";
import SpreadSheetTable from "../../../components/Table";
import BackButton from "../../../components/BackButton";
import { setShowSpreadSheet } from "../../../store/slice/generalSlice";

// Sub-components
const RoomCard = ({ room, selectedRoom, onRoomSelect }) => {
  const active = selectedRoom?.name === room.name;

  return (
    <div
      onClick={() => onRoomSelect(room)}
      className={`bg-background-white rounded-lg px-4 py-5 cursor-pointer flex flex-col gap-1 border-2 
        border-background-dark-gray transition-colors${active ? "border border-secondary" : "hover:border-primary/10"}`}
    >
      <div className="flex justify-between">
        <div className="text-lg font-medium">{room.name}</div>
        <div className="text-primary/50 font-light">{getCurrency(Number(room?.cost || 0))}</div>
      </div>
      <div className="text-primary/50 text-sm">{Number(room?.itemsCount || 0)} item(s) selected</div>
    </div>
  );
};

const CatalogCard = ({ catalog, onViewDetails, onAddProduct }) => {

  const data = typeof catalog?.JsonFeedData === "string" && JSON.parse(catalog?.JsonFeedData);
  let brandName = useCallback(() => formatBrandName(catalog?.from), [catalog?.from]);
  let thumbnail_image = useCallback(() => getFormattedImageLink(catalog?.from, data?.image_link, catalog?.additionalImageLinks), [data?.image_link, catalog?.additionalImageLinks]);

  return (
    <article className="flex overflow-hidden flex-col rounded-md bg-white">
      <div className="flex flex-col justify-center p-2 w-full rounded-sm">
        <img
          loading="lazy"
          src={thumbnail_image()}
          alt={`catalog-${catalog.id}`}
          className="object-cover object-center rounded-md bg-background-gray max-h-[300px]"
        />
      </div>
      <div className="flex flex-col px-4 pt-2 pb-4 w-full bg-white rounded-none">
        <div className="flex flex-col max-w-full text-sm tracking-normal">
          <h2 className="font-semibold text-black">
            {data.title?.substring(0, 30)} {data.title?.length > 30 ? "..." : ""}
          </h2>
          <div className="mt-1 font-light text-stone-300 capitalize">
            {brandName()}
          </div>
        </div>
        <div className="flex gap-10 justify-between items-center mt-5 w-full">
          <div className="self-stretch my-auto text-sm font-semibold tracking-normal leading-none text-black">
            {getCurrency(data.price)}
          </div>
          <div className="flex gap-4 items-center self-stretch my-auto">
            <IconButton
              onClick={() => onViewDetails(data)}
              className=""
              tabIndex={0}
              aria-label="View Details"
            >
              <Eye className="h-6 w-6" />
            </IconButton>
            <IconButton
              onClick={() => onAddProduct(catalog, data)}
              className=""
              tabIndex={0}
              aria-label="Add Product"
            >
              <Add className="h-6 w-6" />
            </IconButton>
          </div>
        </div>
      </div>
    </article>
  );
};

const SelectedProducts = ({ selectedRoom, onRemoveProduct }) => {
  if (!selectedRoom?.items?.length) return null;

  return (
    <div className="flex flex-wrap gap-5">
      {selectedRoom.items.map((item, index) => (
        <div key={index} className="size-16 border border-primary/40 relative group rounded-md">
          <img src={item.image_link} alt="selected-item" className="size-full object-cover object-center rounded-md" />
          <div className="rounded-full size-6 box-center text-sm absolute bg-primary text-background-white border border-background-dark-gray -top-3 -right-3">
            {item.quantity}
          </div>
          <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-40 cursor-pointer transition-opacity" />
          <div className="group-hover:opacity-100 opacity-0 transition-opacity absolute-center bg-black rounded-full">
            <IconButton onClick={() => onRemoveProduct(item.id)}>
              <Delete />
            </IconButton>
          </div>
        </div>
      ))}
    </div>
  );
};

const SheetDetailPage = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { propertySheet, selectedRoom } = useSelector(state => state.property);
  const { showSpreadSheet } = useSelector(state => state.general);

  // State
  const [modalState, setModalState] = useState({ isOpen: false, data: null });
  const [searchState, setSearchState] = useState({ query: "", search: "", from:"" });
  const [uiState, setUiState] = useState({
    showCatalog: false,
    scrolled: false,
    isFormUpdated: false,
    roomImageUploading: false
  });
  const [page, setPage] = useState(1);
  const [user, setUser] = useState(null);
  const [canvasImage, setCanvasImage] = useState(null);
  const [resultData, setResultData] = useState([]);
  const [sheetFormattedData, setSheetFormattedData] = useState([])

  // Queries and Mutations
  const { isLoading, data, isFetching } = useFetchCatalogProductsQuery({
    page,
    perPage: 9,
    search: searchState.search,
    from: searchState.from
  });

  const { isLoading: fetchingSheets, data: mySheets } = useFetchPropertySheetVersionsQuery(
    { userId: user?.userId, propertyId: state.id },
    { skip: !user?.userId }
  );

  const [createSheet, { isLoading: creatingSheet }] = useCreatePropertySheetMutation();
  const [updateSheet, { isLoading: updatingSheet }] = useUpdatePropertySheetMutation();

  // Effects
  useEffect(() => {
    const handleScroll = () => setUiState(prev => ({ ...prev, scrolled: window.scrollY > 1 }));
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    getUser().then(setUser);
  }, []);

  useEffect(() => {
    if (data) {
      setResultData(data?.data);
      localStorage.setItem('lastEvaluatedKey', JSON.stringify({ val: data?.last_evaluated_key }));
    }
  }, [data]);

  useEffect(() => {
    setCanvasImage(selectedRoom?.images?.length ? selectedRoom?.images[0]?.src : state?.thumbnail_image);
  }, [selectedRoom, state?.thumbnail_image]);

  const initializePropertySheet = useCallback(() => {
    const roomsMade = state?.included_rooms?.map((room, i) => ({
      id: i,
      name: room,
    }));

    dispatch(setPropertySheet({
      propertyId: state.id || state?.ItemID,
      rooms: roomsMade,
      buyerTotal: 0,
    }));

    dispatch(setSelectedRoom(roomsMade[0]));
  }, [state, dispatch]);

  useEffect(() => {
    initializePropertySheet();
  }, [initializePropertySheet]);

  useEffect(() => {
    if (!fetchingSheets && mySheets?.length) {
      const propertyRooms = state?.included_rooms?.map((room) => ({
        name: room,
        cost: 0,
        itemsCount: 0,
        items: [],
      }));

      const sheetRooms = mySheets[0]?.rooms;
      const allRooms = propertyRooms.map(room => {
        const sheetRoom = sheetRooms.find(sr => sr.name === room.name);
        return sheetRoom || room;
      });

      const totalBuyerCost = allRooms.reduce((acc, room) => acc + Number(room.cost), 0);

      dispatch(setPropertySheet({
        ...mySheets[0],
        rooms: allRooms,
        buyerTotal: totalBuyerCost
      }));

      dispatch(setSelectedRoom(allRooms[0]));
    }
  }, [fetchingSheets, mySheets, state, dispatch]);

  // Handlers
  const handleImageUpload = async (event) => {
    const selectedFiles = Array.from(event.target.files);

    if (selectedFiles.length > 10) {
      toast.error("Max 10 files can be uploaded at one time");
      return;
    }

    if (selectedFiles.length > 0) {
      setUiState(prev => ({ ...prev, roomImageUploading: true }));
      try {
        const result = await uploadFile(
          selectedFiles[0],
          `images/properties/${state?.id || "default"}/${selectedRoom?.name?.replace(/\s+/g, "-")}`
        );
        setUiState(prev => ({ ...prev, isFormUpdated: true }));
        dispatch(addImage({
          name: selectedRoom?.name,
          image: { src: result?.path }
        }));
      } catch (error) {
        toast.error("Image upload failed");
      } finally {
        setUiState(prev => ({ ...prev, roomImageUploading: false }));
      }
    }
  };

  const handleDeleteRoomImage = useCallback((index) => {
    setUiState(prev => ({ ...prev, isFormUpdated: true }));
    dispatch(removeImage({
      name: selectedRoom?.name,
      id: index,
    }));
  }, [dispatch, selectedRoom?.name]);

  const handleSubmit = async () => {
    const dataToSend = {
      userId: user?.userId,
      propertyId: state.id,
      buyerTotal: propertySheet?.buyerTotal || 0,
      rooms: propertySheet?.rooms?.map(room => ({
        name: room.name,
        items: room.items?.map(item => ({
          id: item.id,
          quantity: item.quantity,
          price: item.price,
          image_link: item.image_link,
          title: item?.title,
          product_link: item?.product_link,
          vendor: item?.vendor,
          availability: item?.availability,
          category: item?.category,
          room: room?.name
        })) || [],
        itemsCount: room.itemsCount || 0,
        cost: room.cost || 0,
        images: room.images || [],
      })),
    };

    const mutation = Number(propertySheet?.version) > 0 ? updateSheet : createSheet;
    const successMessage = Number(propertySheet?.version) > 0 ? "Sheet updated successfully" : "Sheet created successfully";
    const errorMessage = Number(propertySheet?.version) > 0 ? "Sheet update failed" : "Sheet creation failed";

    try {
      await mutation(JSON.stringify(dataToSend)).unwrap();
      setUiState(prev => ({ ...prev, isFormUpdated: false }));
      toast.success(successMessage);
    } catch {
      toast.error(errorMessage);
    }
  };

  const getSimilarCategory = (productType) => {
    console.log("productType",productType)
    if (!productType) return 'Other';
  
    const categories = [
      { category: 'Furniture', subcategories: ['Seating', 'Tables', 'Storage', 'Bedroom', 'Outdoor Furniture'] },
      { category: 'Decor', subcategories: ['Wall Decor', 'Art', 'Accents', 'Botanicals'] },
      { category: 'Lighting & Fans', subcategories: ['Re-chargeable/Plug-in', 'Hardwired', 'Outdoor Lighting', 'Fans'] },
      { category: 'Kitchen & Dining', subcategories: ['Kitchen Accessories', 'Appliances'] },
      { category: 'Bedding & Bath', subcategories: ['Bath', 'Bedroom', 'Mattresses'] },
      { category: 'Rugs', subcategories: ['Area Rugs', 'Runner Rugs', 'Outdoor Rugs'] },
      { category: 'Outdoor & Garden', subcategories: [] },
      { category: 'Kids & Nursery', subcategories: [] },
      { category: 'Organization', subcategories: [] },
      { category: 'Fixtures', subcategories: [] },
      { category: 'Building Materials', subcategories: [] },
      { category: 'Window Treatments', subcategories: [] },
      { category: 'Seasonal & Specialty', subcategories: [] },
    ];
  
    let cleanedProductType = productType
      .replace(/\[.*?\]/g, '') 
      .replace(/>/g, ' ') 
      .replace(/-/g, ' ') 
      .toLowerCase()
      .trim();
  
    const productParts = cleanedProductType.split(/\s+/);
  
    let bestMatch = 'Other';
    let highestMatchCount = 0;
  
    categories.forEach(({ category, subcategories }) => {
      let matchCount = 0;
      const normalizedCategory = category.toLowerCase();
  
     
      if (productParts.includes(normalizedCategory)) {
        matchCount += 2; 
      }
  
      subcategories.forEach(subcategory => {
        const normalizedSubcategory = subcategory.toLowerCase();
        if (productParts.includes(normalizedSubcategory)) {
          matchCount++;
        }
      });
  
      if (matchCount > highestMatchCount) {
        highestMatchCount = matchCount;
        bestMatch = category;
      }
    });
  
    return bestMatch;
  };
  
  const handleAddProduct = useCallback((catalog, data) => {

    setUiState(prev => ({ ...prev, isFormUpdated: true }));
    const category = getSimilarCategory(catalog?.productType);
    dispatch(addProduct({
      name: selectedRoom?.name,
      product: {
        id: catalog?.id,
        quantity: 1,
        title: data?.title,
        image_link: data?.image_link,
        price: data?.price,
        ItemID: catalog?.ItemID,
        product_link: data?.link || '',
        vendor: catalog?.from || data?.from || '',
        availability: data?.availability || '',
        category: category,
        ordered: catalog?.ordered || false
      }
    }));
  }, [dispatch, selectedRoom?.name]);

  const transformDataForSpreadSheet = (rooms = []) => {
    return rooms.flatMap((room) => {
      if (!room?.items?.length) return [];
      return room?.items?.map((product, index) => ({
        id: product?.id || '',
        qty: product?.quantity || 1,
        product:  `${product?.title?.substring(0, 50)}${product?.title?.length > 50 ? "..." : ""}`  || '',
        vendor: product?.vendor || 'wayfair',
        room: room?.name || '',
        category: product?.category || '',
        link: product?.product_link || '',
        availability: product?.availability || '',
        ordered: product?.ordered,
      }));
    });
  };
  useEffect(()=>{
    const sheetData  = transformDataForSpreadSheet(propertySheet?.rooms)
    setSheetFormattedData(sheetData)
  },[showSpreadSheet,propertySheet])

  


  const handleBackClick = () => {
    dispatch(setShowSpreadSheet(false));
  };

  const orderedProductHandler = async (row, checked) => {
    try {
        if (!propertySheet || !propertySheet.rooms) return;

        const dataToSend = {
            userId: user?.userId,
            propertyId: state.id,
            buyerTotal: propertySheet?.buyerTotal || 0,
            rooms: propertySheet.rooms.map(room => ({
                name: room.name,
                items: room.items?.map(item => ({
                    id: item.id,
                    quantity: item.quantity,
                    price: item.price,
                    image_link: item.image_link,
                    title: item.title,
                    product_link: item.product_link,
                    vendor: item.vendor,
                    availability: item.availability,
                    category: item.category,
                    room: room.name,
                    ordered: row?.id === item.id ? checked : item.ordered, // Updating ordered status
                    itemsCount: room.itemsCount || 0,
                    cost: room.cost || 0,
                    images: room.images || [],
                })) || [],
            })),
        };

        // Dispatch updateProduct action to update Redux store
        dispatch(updateProduct({
            name: row?.room || selectedRoom?.name,
            id: row?.id,  // Ensure correct product ID
            updatedData: { ordered: checked } // Only updating the ordered status
        }));

        // Send the updated data to the backend
        await updateSheet(JSON.stringify(dataToSend)).unwrap();
    } catch (error) {
        console.error("Error:", error);
        toast.error(error?.message || "Something went wrong");
    }
};

  
  return (
    <>
      <CatalogDetailModal
        open={modalState.isOpen}
        openSet={(isOpen) => setModalState(prev => ({ ...prev, isOpen }))}
        data={modalState.data}
      />
      {
        !showSpreadSheet ?
          <div className="flex flex-col">
            <Sidebar>
              <div className="flex flex-col justify-between h-full">
                <div className="flex flex-col gap-5 w-full">
                  <Header text="Room Sheets" includeBackBtn />
                  <div className="flex flex-col gap-3 lg:px-3 lg:max-h-[calc(100vh-230px)] overflow-y-auto">
                    {propertySheet?.rooms?.length ? (
                      propertySheet.rooms.map((room, index) => (
                        <RoomCard
                          key={index}
                          room={room}
                          selectedRoom={selectedRoom}
                          onRoomSelect={(room) => dispatch(setSelectedRoom(room))}
                        />
                      ))
                    ) : (
                      <div>This property has no rooms...</div>
                    )}
                  </div>
                </div>
                <div className="fixed lg:relative bg-background-white lg:bg-transparent lg:pr-0 px-10 lg:px-3 border-t lg:border-none border-t-primary/10 py-6 lg:py-0 z-10 left-0 md:pl-32 lg:pl-0 bottom-0 w-full">
                  <div className="lg:border-t border-t-primary/20 flex justify-between lg:pt-3 text-lg font-medium">
                    <div>Est. Net Price</div>
                    <div>{getCurrency(propertySheet?.buyerTotal)}</div>
                  </div>
                </div>
              </div>
            </Sidebar>

            <PageWrapper className="!py-0 !pb-5 !px-5">
              <div className="flex flex-1 flex-col gap-8 size-full relative max-h-screen overflow-y-auto">
                <div className="grid grid-cols-2 gap-5">
                  <div className={`col-span-2 flex items-center w-full gap-4 py-3 px-5 sticky top-0 z-10 ${uiState.scrolled ? 'bg-white shadow-sm' : 'bg-white shadow-sm'
                    }`}>
                    <div className="flex-1 flex gap-4">
                      {selectedRoom && propertySheet?.rooms?.length && (
                        <RoomImages
                          selectedRoom={propertySheet?.rooms?.find(room => room.name === selectedRoom?.name)}
                          propertySheet={propertySheet} canvasImageSet={setCanvasImage}
                          handleDeleteRoomImage={handleDeleteRoomImage}
                          isFormUpdatedSet={(isUpdated) => setUiState(prev => ({ ...prev, isFormUpdated: isUpdated }))} />
                      )}
                      <div className="relative size-16 flex justify-center items-center bg-[#EAEAEA] border-[1px] border-[#BCC2C7] border-dashed rounded-lg">
                        <input type="file" onChange={handleImageUpload} className="w-20 opacity-0 absolute inset-0 z-20 cursor-pointer" multiple disabled={uiState.roomImageUploading} />
                        <AddCircle className={`size-8 text-primary ${uiState.roomImageUploading ? 'cursor-not-allowed' : 'cursor-pointer'}`} />
                      </div>
                    </div>
                    {!uiState.isFormUpdated ? (<ShareDesign propertyId={state.id} propertySheetId={propertySheet?.id} />) :
                      (<Button loading={creatingSheet || updatingSheet} small onClick={handleSubmit}> Save Changes </Button>)}
                  </div>
                  <div className={`col-span-2 rounded-xl overflow-hidden ${uiState.showCatalog ? 'max-h-[50vh]' : 'max-h-[70vh]'} relative`}>
                    <ImageComponent imageKey={canvasImage} className="size-full object-cover lg:min-h-52" />
                    <div className="flex justify-between items-center absolute bottom-5 left-2">
                      <SelectedProducts
                        selectedRoom={propertySheet?.rooms?.find(room => room.name === selectedRoom?.name)}
                        onRemoveProduct={(id) => {
                          setUiState(prev => ({ ...prev, isFormUpdated: true }));
                          dispatch(removeProduct({ name: selectedRoom?.name, id }))
                        }}
                      />
                    </div>
                  </div>
                </div>

                {selectedRoom ? (
                  <div className="w-full h-full flex flex-col gap-12">
                    <div className="flex flex-col gap-6 flex-1">
                      <div className="flex">

                        <Header text="Product Catalog" />
                        <Tooltip title="Show Spreadsheet">
                        </Tooltip>
                      </div>
                      <div className="flex w-full flex-col-reverse md:flex-row items-center justify-between gap-4">
                        <div className="flex w-full items-stretch">
                          <Input
                            value={searchState.query}
                            onChange={(e) => setSearchState(prev => ({ ...prev, query: e.target.value }))}
                            className="bg-background-white !rounded-r-none"
                            containerClassName="w-full"
                            placeholder="Search Products..."
                            leftIcon={<Search />}
                          />
                          <Button
                            disabled={isLoading || isFetching}
                            onClick={() => setSearchState(prev => ({ ...prev, search: prev.query }))}
                            className="!rounded-l-none !w-fit"
                          >
                            Search
                          </Button>
                          <IconButton
                            variant="outlined"
                            style={{ border: "1px solid #BCC2C7", backgroundColor: "transparent" }}
                            disabled={isLoading || isFetching}
                            onClick={() => setUiState(prev => ({ ...prev, showCatalog: !prev.showCatalog }))}
                            className="!min-w-24 !text-black !rounded-lg !ml-5 !text-sm !font-normal !flex !justify-center"
                          >
                            {uiState.showCatalog ? <Hide className="size-5 mr-2" /> : <Show className="size-5 mr-2" />}
                            {uiState.showCatalog ? "Close" : "Open"}
                          </IconButton>
                        </div>
                      </div>
                    </div>
                    {uiState.showCatalog && (
                      <div className="flex flex-col md:flex-row gap-5">
                        <FilterSidebar setSearchState={setSearchState} />
                        <div className="w-full flex flex-col flex-1">
                          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-5">
                            {isLoading ? (
                              Array.from({ length: 9 }).map((_, index) => <PropertySkeleton key={index} skeleton />)
                            ) : resultData.length ? (
                              resultData.map((catalog, index) => (
                                <CatalogCard
                                  key={index}
                                  catalog={catalog}
                                  onViewDetails={(data) => setModalState({ isOpen: true, data })}
                                  onAddProduct={handleAddProduct}
                                />
                              ))
                            ) : (
                              <div>No property catalog found...</div>
                            )}
                          </div>
                          {(!isLoading || !isFetching) && (
                            <Pagination
                              className="mx-auto my-10"
                              page={page}
                              pageSet={setPage}
                              count={Math.ceil(data?.total / 9)}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="flex-1 mt-20 box-center">Select Room to continue</div>
                )}
              </div>
            </PageWrapper>
          </div> :
          <div className="px-[2rem] md:pl-[8rem] flex flex-col items-start w-full">
            <div className="my-1">
              <Tooltip title="Back">
                <IconButton onClick={handleBackClick}>
                  <Back/>
                </IconButton>
              </Tooltip>
            </div>
            <SpreadSheetTable rowsData={sheetFormattedData} selectHandler={orderedProductHandler}/>
          </div>
      }
    </>
  );
};

export default SheetDetailPage;