import React from 'react';
import { ReactComponent as Delete } from "../../assets/icons/delete.svg";
import { deleteProperty, getImageLink } from '../../services/amplify/property';
import { setEditProperty } from '../../store/slice/propertySlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import MuiModal from '../MuiModal';

const PropertyCard = ({ property }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [deleteConfirmation, deleteConfirmationSet] = React.useState(false);
  function handlePropertyClick() {
    // navigate('/property-sheet/detail', { state: property });
    dispatch(setEditProperty(property));
    navigate('/property/update/' + property.id,{ state: property });
    
  }

  function handleDeletePropertyConfirmation(event) {
    event.stopPropagation(); // Prevent triggering handlePropertyEdit when delete is clicked
    deleteConfirmationSet(true);
  }

  async function handleDeleteProperty() {
    await deleteProperty(property);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  function onCloseModal() {
    deleteConfirmationSet(false);
  }


  return (
    <div
      onClick={() => handlePropertyClick(property)}
      className="property-card flex flex-col rounded-lg overflow-hidden shadow-lg bg-background-white cursor-pointer h-[400px] md:h-[438px] xl:h-[500px] z-[10]"
    >
      {deleteConfirmation && <MuiModal title="Delete Property" body="Are you sure you want to delete this property?" handleSubmit={handleDeleteProperty} onCloseModal={onCloseModal} />}
      <div className="h-[75%]">
        <img alt={property.property_name} src={getImageLink(property.thumbnail_image)} className="size-full object-cover object-center" />
      </div>
      <div className="flex-1 p-4 flex items-center justify-between gap-2">
        <div className="">
          <div className="text-xl font-medium">{property.property_name}</div>
          <div className="">{property.address || property?.street_address}</div>
        </div>
        <div className="flex justify-between items-center">
          <div className="delete-icon-wrapper flex gap-2 items-center">
            <Delete onClick={handleDeletePropertyConfirmation} className="delete-icon" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PropertyCard;
