import React, { useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button, Drawer, useMediaQuery, IconButton, Divider, Tooltip } from "@mui/material";
import { colors } from "../../themes/themes";
import { ReactComponent as Logo } from "../../assets/logo/snall-logo.svg";
import { ReactComponent as Paint } from "../../assets/icons/paint.svg";
import { ReactComponent as Spreadsheet } from "../../assets/icons/spreadsheet.svg";
import { ReactComponent as Logout } from "../../assets/icons/logout.svg";
import { config } from "../../constants";
import { clearUser } from "../../store/slice/authSlice";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { handleSignOut } from "../../services/amplify/helpers";
import { setShowSpreadSheet } from "../../store/slice/generalSlice";


const Sidebar = ({ open, openSet }) => {
  const openSpreadSheetHandler = () => {
    dispatch(setShowSpreadSheet(true));
  }
  const closeSpreadSheetHandler = () => {
    dispatch(setShowSpreadSheet(false));
  }
  const links = [
    { name: "Paint" , handler: closeSpreadSheetHandler, icon: <Paint /> },
    { name: "Spreadsheet", handler: openSpreadSheetHandler, icon: <Spreadsheet /> },
  ];
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery(config.MD_BREAKPOINT);

  async function logout() {
    let response = await handleSignOut();
    if (response.isSignedOut) {
      dispatch(clearUser());
      toast.success("Logout Success");
    } else {
      toast.error(response.error);
    }
  }

  function handleClose() {
    openSet((prev) => !prev);
  }

  useEffect(() => {
    open && handleClose();
  }, [location.pathname]);


  return (
    <Drawer
      sx={{
        "& .MuiDrawer-paper": {
          width: isSmallScreen ? "96px" : "96px",
          borderRadius: "0px 20px 20px 0px",
        },
      }}
      anchor="left"
      open={open}
      onClose={handleClose}
      hideBackdrop={isSmallScreen ? false : true}
      variant={isSmallScreen ? "temporary" : "permanent"}
    >
      <div className="w-full h-full p-4 py-10 flex flex-col items-center justify-between">
        <div className="flex flex-col gap-10">
          <div onClick={() => navigate("/", { state: { from: location.pathname, ...location.state }, replace: false })} className="cursor-pointer">
            <Logo />
          </div>
          <Divider flexItem />
          <div className="flex flex-col gap-10">
            {location.pathname === "/property-sheet/detail" &&
              links.map((link, index) => <Link key={index} link={link} isSmallScreen={isSmallScreen} />)}
          </div>
        </div>

        <Tooltip title="Logout">
          <IconButton onClick={logout} className="mt-auto">
            <Logout />
          </IconButton>
        </Tooltip>
      </div>
    </Drawer>
  );
};

export default Sidebar;

const Link = ({ link, isSmallScreen }) => {
  const location = useLocation();
  const navigate = useNavigate();

  if (link?.handler) {
    return (<div className="flex gap-2 items-center cursor-pointer" onClick={link?.handler}>
      {link.icon}
      {/* {isSmallScreen && <div className={`${props.isActive ? "" : ""}`}>{link.name}</div>} */}
    </div>)
  }

  return (
    <NavLink
      to={link.path}
      state={{ from: location.pathname, ...location.state }}
      onClick={(e) => {
        e.preventDefault();
        navigate(link.path, { state: { from: location.pathname, ...location.state }, replace: false });
      }}
    >
      <div className="flex gap-2 items-center">
        {link.icon}
        {/* {isSmallScreen && <div className={`${props.isActive ? "" : ""}`}>{link.name}</div>} */}
      </div>
    </NavLink>
  );
};
